body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.smallInput {
  position: absolute;
  right: 10px;
  width: 100px;
  padding: 10px;
  border: none;
  background-color: rgb(202, 203, 205);
  color: rgb(65, 65, 65);
  border-radius: 10px;
}
